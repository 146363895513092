<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="ctahabientes" class="p-datatable-customers" v-model:selection="selectedCtaHabientes" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['codigo', 'nombre_comercial', 'nit', 'cta_habiente_tipo']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} cuentahabientes" :loading="loading">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Cuentahabientes</h5>
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Buscar..." />
							</span>
						</div>
					</template>

					<Column field="codigo" header="Código" :sortable="true" headerStyle="width: 10%">
						<template #body="slotProps">
							<span class="p-column-title">Código</span>
							{{slotProps.data.codigo}}
						</template>
					</Column>
					<Column field="nombre_comercial" header="Nombre Comercial" :sortable="true" headerStyle="width: 40%">
						<template #body="slotProps">
							<span class="p-column-title">Nombre Comercial</span>
							{{slotProps.data.nombre_comercial}}
						</template>
					</Column>
					<Column field="nit" header="NIT" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">NIT</span>
							{{ slotProps.data.nit }}
						</template>
					</Column>
					<Column field="telefono" header="Teléfono" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Teléfono</span>
							{{ slotProps.data.telefono }}
						</template>
					</Column>
					<Column field="categoria" header="Categoría" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Categoría</span>
							{{ slotProps.data.categoria }}
						</template>
					</Column>			
					<Column field="cta_habiente_tipo" header="Tipo" :sortable="true"/>	
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editCtaHabiente(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteCtaHabiente(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="ctaHabienteDialog" :style="{width: '250px'}" header="Cuentahabiente" :modal="true" class="p-fluid">
					<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
					<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col">
						<label for="codigo">Código</label>
						<InputText id="codigo" v-model.trim="contacto.codigo" autofocus :class="{'p-invalid': submitted && !ctahabiente.codigo}" />
					</div>
					<div class="p-field p-col">
						<label class="p-col" for="nombre">Nombres</label>
						<InputText v-model="contacto.nombre" />
					</div>
					<div class="p-field p-col">
						<label class="p-col" for="apellido">Apellidos</label>
						<InputText v-model="contacto.apellido" />
					</div>			
					<div class="p-field p-col">
						<label class="p-col" for="email">Email</label>
						<InputText v-model="contacto.email" />
					</div>			
					<div class="p-field p-col">
						<label class="p-col" for="telefono">Teléfono</label>
						<InputText v-model="contacto.telefono" />
					</div>									
					<div class="p-field p-col">
						<label class="p-col" for="extension">extension</label>
						<InputText v-model="contacto.extension" />
					</div>								
					<div class="p-field p-col">
						<label class="p-col" for="celular">Celular</label>
						<InputText v-model="contacto.celular" />
					</div>						
					<div class="p-field p-col">
						<label class="p-col" for="celular">Departamento</label>
						<InputText v-model="contacto.departamento" />
					</div>						
					</div>
					<TabView>
						<TabPanel>
							<template #header>
							<i class="pi pi-globe p-m-1"></i>
							<span>Generales</span>
							</template>
							<div class="p-field">
								<label for="nit">NIT</label>
								<InputText id="NIT" v-model.trim="ctahabiente.nit" required="true" :class="{'p-invalid': submitted && !ctahabiente.nit}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.nit">NIT es requerido.</small>
							</div>					
							<div class="p-field">
								<label for="nombre_comercial">Nombre Comercial</label>
								<InputText id="nombre_comercial" v-model.trim="ctahabiente.nombre_comercial" required="true" :class="{'p-invalid': submitted && !ctahabiente.nombre_comercial}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.nombre_comercial">Nombre comercial es requerido.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Razón Social</label>
								<InputText id="razon_social" v-model="ctahabiente.razon_social" required="true"  :class="{'p-invalid': submitted && !ctahabiente.razon_social}"/>
								<small class="p-invalid" v-if="submitted && !ctahabiente.razon_social">La razon soical es requerida.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Teléfono</label>
								<InputText id="telefono" v-model.trim="ctahabiente.telefono" required="true" :class="{'p-invalid': submitted && !ctahabiente.telefono}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.telefono">El telefono es requerido.</small>
							</div>	
							<div class="p-field">
								<label for="razon_social">Email</label>
								<InputText id="email" v-model.trim="ctahabiente.email" required="true" :class="{'p-invalid': submitted && !ctahabiente.email}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.email">El email es requerido.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Website</label>
								<InputText id="website" v-model.trim="ctahabiente.website" required="false" />
							</div>																			
							<div class="p-field">
								<label for="id_cta_habiente_categoria">Categoría</label>
								<Dropdown v-model="ctahabiente.id_cta_habiente_categoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_cta_habiente_categoria" placeholder="Seleccionar..." />
							</div>					
							<div class="p-field">
								<label for="id_region">Región</label>
								<Dropdown v-model="ctahabiente.id_region" :options="lkpRegiones" optionLabel="region" optionValue="id_region" placeholder="Seleccionar..." />
							</div>

							<div class="p-field">
								<label for="id_precio_lista">Lista de Precios</label>
								<Dropdown v-model="ctahabiente.id_precio_lista" :options="lkpPreciosLista" optionLabel="precio_lista" optionValue="id_precio_lista" placeholder="Seleccionar..." />
							</div>		

							<div class="p-field">
								<label for="id_producto_lista">Lista de Productos</label>
								<Dropdown v-model="ctahabiente.id_producto_lista" :options="lkpProductosLista" optionLabel="lista" optionValue="id_producto_lista" placeholder="Seleccionar..." />
							</div>	

							<div class="p-field">
								<label for="pct_descuento">% Descuento</label>
								<InputNumber id="pct_descuento" v-model="ctahabiente.pct_descuento" prefix="%" />
							</div>	
							<div class="p-formgrid p-grid">
							<div class="p-field">
								<label for="limite_credito">Límite de Crédito</label>
								<InputNumber id="limite_credito" v-model="ctahabiente.limite_credito" mode="decimal" :minFractionDigits="2"/>
							</div>		
							<div class="p-field">
								<label for="id_moneda">Moneda</label>
								<Dropdown v-model="ctahabiente.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />
							</div>	
							</div>	
							<div class="p-field">
								<label for="nit_documento">Facturar al NIT</label>
								<InputText id="nit_documento" v-model.trim="ctahabiente.nit_documento" />
							</div>	

							<div class="p-field">
								<label for="nombre_documento">Emitir Documentos a Nombre de</label>
								<InputText id="nombre_documento" v-model.trim="ctahabiente.nombre_documento" />
							</div>	

							<div class="p-field">
								<label for="dias_entrega">Dias de Entrega</label>
								<InputNumber id="dias_entrega" v-model="ctahabiente.dias_entrega" showButtons :min="0"/>
							</div>			
						</TabPanel>
						<TabPanel>
							<template #header>
							<i class="pi pi-users p-m-1"></i>
							<span>Contactos</span>
							</template>
							<DataTable ref="contactos" :value="ctahabiente.contactos" class="p-datatable-sm" dataKey="id_contacto" :paginator="true" :rows="5"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPag Link CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
									currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
							<Column field="nombre" header="Nombres" headerStyle="width: 30%">
								<template #body="slotProps">
									{{slotProps.data.nombre}} {{slotProps.data.apellido}}
								</template>
							</Column>
							<Column field="celular" header="Celular" headerStyle="width: 30%"></Column>
							<Column field="email" header="email" headerStyle="width: 30%"></Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="editContacto(slotProps.data)" />
									<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDeleteContacto(slotProps.data)" />
								</template>
							</Column>							
							</DataTable>
						</TabPanel>
						<TabPanel>
							<template #header>
							<i class="pi pi-home p-m-1"></i>
							<span>Direcciones</span>
							</template>
							<DataTable ref="direcciones" :value="ctahabiente.direcciones" class="p-datatable-sm" dataKey="id_cta_habiente_direccion" :paginator="true" :rows="5"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPag Link CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
									currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
							<Column field="descripcion" header="Descripcion" headerStyle="width: 30%"></Column>
							<Column field="direccion" header="Dirección" headerStyle="width: 30%"></Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="editDireccion(slotProps.data)" />
									<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDeleteDireccion(slotProps.data)" />
								</template>
							</Column>							
							</DataTable>
						</TabPanel>						
					</TabView>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveCtaHabiente" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCtaHabienteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="ctahabiente">Esta seguro que desea eliminar al cuentahabiente seleccionado?<br><b>[{{ ctahabiente.codigo }}-{{ctahabiente.nombre_comercial}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteCtaHabienteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteCtaHabiente" />
					</template>
				</Dialog>

				<!-- <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog> -->
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import GrlService from '../service/grl.service';

export default {
	data() {
		return {
			ctahabientes: null,
			ctaHabienteDialog: false,
			deleteCtaHabienteDialog: false,
			deleteCtaHabientesDialog: false,
			ctahabiente: {},		
			lkpCategorias: this.$store.state.auth.currentAppCtx.ctahabiente_categorias,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lkpRegiones: this.$store.state.auth.currentAppCtx.ctahabiente_regiones,
			lkpCtaHabienteTipos: this.$store.state.auth.currentAppCtx.ctahabiente_tipos,
			lkpPreciosLista: this.$store.state.auth.currentAppCtx.precio_listas,
			lkpProductosLista: this.$store.state.auth.currentAppCtx.producto_listas,
			selectedCtaHabientes: null,
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	mounted() {
		this.loading = true;

		GrlService.getCtaHabientes(this.$store.state.auth.currentAppCtx).then(
			data => {
				this.ctahabientes = data;
				this.loading = false;
			}
		).catch(
							data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		)		
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'GTQ'});
			return;
		},
		openNew() {
			this.$router.push({name: 'ctahabiente', params: {id: ''}});	
		},
		hideDialog() {
			this.ctaHabienteDialog = false;
			this.submitted = false;
		},
		saveCtaHabiente() {			
			GrlService.registrarCtaHabiente(this.$store.state.auth.currentAppCtx, this.ctahabiente);

			this.submitted = true;
			this.ctaHabienteDialog = false;
		},
		editCtaHabiente(ctahabiente) {			
			this.$router.push({name: 'ctahabiente', params: {id: ctahabiente.id_cta_habiente_info}});		
		},
		confirmDeleteCtaHabiente(ctahabiente) {
			this.ctahabiente = ctahabiente;
			this.deleteCtaHabienteDialog = true;
		},
		deleteCtaHabiente() {
			GrlService.eliminarCtaHabiente(this.$store.state.auth.currentAppCtx, this.ctahabiente).then(() => {
					this.ctahabientes = this.ctahabientes.filter(val => val.id_cta_habiente_info !== this.ctahabiente.id_cta_habiente_info);
					this.deleteCtaHabienteDialog = false;
					this.ctahabiente = {};
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'El cuentahabiente ha sido eliminado!', life: 3000})
			}
			).catch(
				data => {
					this.deleteCtaHabienteDialog = false;
					this.ctahabiente = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			this.deleteCtaHabientesDialog = false;
			this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
